import React, { useState, useRef } from "react";
import { Layout, Row, Spin, Col, Card, Space, Progress } from "antd";
// import ReactToPrint from "react-to-print";
// import Pdf from "react-to-pdf";

import axios from "axios";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Button,
  notification,
  Modal,
  Result,
} from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";
import { data } from "../util/data";
import { useReactToPrint } from "react-to-print";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import store from "../redux/store";
import html2canvas from "html2canvas";
import FormPdf from "./form";
//import { image } from "../images";
//import { position } from "html2canvas/dist/types/css/property-descriptors/position";

const { Search } = Input;

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const { Content, Footer } = Layout;

class FormComp extends React.Component {

  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
    //this.onPrint = this.onPrint.bind(this);
    this.myRef = React.createRef();
  }

  state = {
    obj: {},
    loading: false,
    loading2: false,
    loading3: false,
    visible: false,
    visible_email: false,
    verified: false,
    verified_email: false,
    otpSent: false,
    emailOtpSent: false,
    dist: "",
    mobile: "",
    email: "",
    submit: false,
    fireworksShow: false,
    auth: {},
    save: "validate",
    authenticating: true
  };
  formRef = React.createRef();
  //this.componentRef = useRef();
  fetchData = (fetchData)=> {
    // console.log(fetchData)
    const value = {
      name: fetchData?.NAME_OF_PUJA_COMMITTEE || fetchData?.name,
      president_secretary_email: fetchData?.SECRETARY_EMAIL || fetchData?.president_secretary_email,
      president_secretary_wa_no: fetchData?.SECRETARY_WHATSAPP || fetchData?.president_secretary_wa_no,
      president_secretary_mobile: fetchData?.SECRETARY_PHONE || fetchData?.president_secretary_mobile,
      // president_secretary_pin: fetchData?.SECRETARY_ADDRESS?.split("-")[1]?.trim() || fetchData?.president_secretary_pin,
      president_secretary_pin: "",
      president_line_one: fetchData?.SECRETARY_ADDRESS || fetchData?.president_secretary_address,
      president_secretary_father_name: fetchData?.SECRETARY_FATHER_NAME || fetchData?.president_secretary_father_name,
      puja_name: fetchData?.PUJA_NAME === "DP" ? "Durga Puja" : fetchData?.PUJA_NAME === "KP" ? "Kali Puja" : fetchData?.puja_name || "Other Puja",
      president_secretary_name: fetchData?.SECRETARY_NAME || fetchData?.president_secretary_name,
      type_of_puja: fetchData?.TYPE_OF_THE_PUJA || fetchData?.type_of_puja,
      immersion_proposed_date: moment(fetchData?.IMMERSION_PROPOSED_DATE).format('YYYY-MM-DD'),
      immersion_name_of_ghat_and_city: fetchData?.IMMERSION_GHAT_CITY,
      ward_no: fetchData?.WARD_NO || fetchData?.ward_no,
      // police_station: fetchData?.,
      district: fetchData?.DISTRICT_COMMISSIONERATE === "KOLKATA POLICE" ?  "Kolkata" : fetchData?.district || "",
      // pin: (fetchData?.PUJA_COMMITTEE_ADDRESS)?.split("-")[1]?.trim() || fetchData?.pin,
      pin: "",
      puja_committee_line_one: fetchData?.PUJA_COMMITTEE_ADDRESS || fetchData?.address_of_puja_venue,
      caterer_name: fetchData?.caterer_name,
      caterer_mobile: fetchData?.caterer_mobile,
      caterer_wa_no: fetchData?.caterer_wa_no,
      decorator_name: fetchData?.decorator_name,
      decorator_wa_no: fetchData?.decorator_wa_no,
      decorator_mobile: fetchData?.decorator_mobile,
      idol_maker_name: fetchData?.idol_maker_name,
      idol_maker_mobile: fetchData?.idol_maker_mobile,
      idol_maker_wa_no: fetchData?.idol_maker_wa_no,
      sound_system_supplier_mobile: fetchData?.sound_system_supplier_mobile,
      sound_system_supplier_name: fetchData?.sound_system_supplier_name,
      sound_system_supplier_wa_no: fetchData?.sound_system_supplier_wa_no,
      nearest_fire_station: fetchData?.nearest_fire_station,
      nearest_hospital: fetchData?.nearest_hospital,
      immersion_fireworks_usage: fetchData?.immersion_fireworks_usage ? "Yes" : "No",
      is_final_submit: fetchData?.is_final_submit
    }
    return value
  }

  componentDidMount(){
    const params = new URLSearchParams(window.location.href).get("asn_id")
    const auth = new URLSearchParams(window.location.href).get("auth")
    const api_key = 'mlcJB1bUulWyLyAJt50lEA=='
    const getAssanData= (asn_id)=> {
      let details = {
        'asn_id': asn_id
        // 'ref_hash': ref_hash
      }
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
      };
    
      // const data =
        axios.post("/utsabapp/get_puja",formBody,config)
        .then((response) => {
          console.log(response)
          const value =  this.fetchData(response?.data?.result[0])
          this.setState({obj: value})
        })
        .catch((error) => {
          return error;
        });
      // return data;
    }
    const getSavedData= (asn_id)=> {
      // const data =
        axios.get(`/kp/save_for_later?asn_id=${asn_id}`)
        .then((response) => {
          console.log(response?.data?.data)
          if(Object.keys(response?.data?.data)?.length > 0){
            const value = this.fetchData(response?.data?.data)
            this.setState({obj: value})
          } 
          Object.keys(response?.data?.data)?.length === 0 && getAssanData(asn_id)
        })
        .catch((error) => {
          // console.log(error)
          error && getAssanData(asn_id)
          return error;
        });
      // return data;
    }
    const authentication = ()=> {
      let details = {
        'asn_id': params
        // 'ref_hash': ref_hash
      }
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
      };
    
      // const data =
        axios.post("/utsabapp/get_puja",formBody,config)
        .then((response) => {
          console.log(response)
          if(response?.data?.result?.length > 0 && auth === api_key) getSavedData(params)
          this.setState({authenticating: false})
          if(response?.data?.result?.length > 0 && auth === api_key) notification["success"]({
            message: "Authentication successful!!",
          });
          (response?.data?.result?.length > 0 && auth === api_key) && this.setState({auth: {asn_id: params, auth: auth}});
          (response?.data?.result?.length > 0 && auth === api_key) && store.dispatch({ type: "Auth", payload: true});
        })
        .catch((error) => {
          this.setState({authenticating: false})
          return error;
        });
      }
    // getAssanData(params)
    authentication()
  }
  // useEffect(()=> {
  //     const getAssanData= ()=> {
  //       let body = JSON.stringify({
  //         'asn_id': 'ASN-DP-2022-000922',
  //         'ref_hash': 'KP3X9b' 
  //       });
  //       var config = {
  //         method: "post",
  //         url: `https://api.kolkatapolice.org/utsabapp/get_puja`,
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded', 
  //         },
  //       };
      
  //       const data = axios(config)
  //         .then((response) => {
  //           return response;
  //         })
  //         .catch((error) => {
  //           return error;
  //         });
  //       return data;
  //     }
  //   },[])

  _dist = (e) => {
    this.setState({ dist: e });
  };
  _phoneOtpSend = (value) => {
    console.log(value);
    if (value.length === 10) {
      this.setState({ loading2: true });
      this.setState({ mobile: value });

      const data = { mobile: value };

      axios
        .post("/generate_otp", data)
        .then((res) => {
          console.log(res.data);
          this.setState({ loading2: false });

          this.setState({ otpSent: true });

          this.setState({ visible: true });

          console.log("otp sent");
        })
        .catch((err) => {
          console.log(err, err.response);
          this.setState({ loading2: false });
        });
    }
  };

  //email otp sending
  _emailOtpSend = (value) => {
    console.log(value);
    
      this.setState({ loading3: true });
      this.setState({ email: value });

      const data = { email_id: value };

      axios
        .post("https://greenpuja.wbpcb.gov.in:7443/api/get_otp_president_secretary_email",data)
        .then((res) => {
          console.log(res.data);
          this.setState({ loading3: false });

          this.setState({ emailOtpSent: true });

          this.setState({ visible_email: true });

          console.log("otp sent");
        })
        .catch((err) => {
          console.log(err, err.response);
          this.setState({ loading3: false });
          notification["error"]({
            message: `${err?.response?.data?.msg}`
          });
        });
  };
  

  _verifySend = (value) => {
    console.log(value);
    if (value.length === 6) {
      const data = { mobile: this.state.mobile, otp: value };
      this.setState({ loading: true });
      axios
        .post("/verify_otp", data)
        .then((res) => {
          console.log(res.data);
          this.setState({ verified: true });

          console.log("verified");
          this.setState({ loading: false });

          notification["success"]({
            message: "OTP Verified Successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
          notification["error"]({
            message: "Wrong OTP",
          });
        });
    }
  };
  //email verify otp
  _verifyEmail = (value) => {
    console.log(value);
    if (value.length === 6) {
      const data = { otp: value };
      this.setState({ loading: true });
      axios
        .post("https://greenpuja.wbpcb.gov.in:7443/api/verify_otp_for_president_secretary_email", data)
        .then((res) => {
          console.log(res.data);
          this.setState({ verified_email: true });

          console.log("verified");
          this.setState({ loading: false });

          notification["success"]({
            message: "OTP Verified Successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
          notification["error"]({
            message: "Wrong OTP",
          });
        });
    }
  };

  onFinish = (values) => {
    console.log(values);

    const committeeData = {
      ...values,
      address_of_puja_venue:
        values.puja_committee_line_one,
      // president_secretary_address:
      //   values.president_line_one + " " + values.president_line_two,
      // idol_maker_address: values.idol_line_one + " " + values.idol_line_two,
      // sound_system_supplier_address:
      //   values.sound_line_one + " " + values.sound_line_two,
      // decorator_address:
      //   values.decorator_line_one + " " + values.decorator_line_two,
      // fireworks_supplier_address:
      //   values.supplier_line_one + " " + values.supplier_line_two,
      immersion_fireworks_usage: values.immersion_fireworks_usage === "Yes" ? true : false,
      asn_id: this.state.auth.asn_id
    };



    this.setState({ obj: committeeData});
    // this.state.verified && store.dispatch({ type: "TF", payload: true });
    this.state.save === "save" ? this.handleSave({...committeeData}) : store.dispatch({ type: "TF", payload: true });
    this.props.onClick(this.state.obj)
    

    // if (this.state.otpSent) {
    //   !this.state.verified &&
    //     notification["error"]({
    //       className: "noti",
    //       message: "Verify OTP",
    //       description: `OTP has been sent to your phone no. ${this.state.mobile} ,verify to proceed`,
    //     });
    // } else {
    //   notification["error"]({
    //     className: "noti",
    //     message: "Verification Pending",
    //     description: `Verify Mobile Number`,
    //   });
    // }
    //this.props.onClick(this.state.obj)
  };
  
  onClick(props){
    this.props.onClick(this.state.obj)
    //console.log(this.state.obj);
  };
  // onPrint(props){
  //   this.props.onPrint(this.state.submit)
  // }

  onReset = () => {
    this.formRef.current.resetFields();
    this.setState({ obj: {is_final_submit: false} });
  };

  // handlePrint = useReactToPrint({
  //   content: () => this.myRef.current,
  //   onAfterPrint: () => {
  //     window.location.reload("false");
  //   },
  // });
  

  _sForm = () => {
    this.setState({ submit: true });

    axios
      .post("/kp/final_submit", this.state.obj)
      .then((res) => {
        this.setState({ submit: false });

        console.log(res.data);
        store.dispatch({ type: "PRINT", payload: false });
        store.dispatch({ type: "success", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notification["warning"]({
          message: "Submitted",
          description: `Your Document has been Already submitted.`,
        });
        this.setState({ submit: false });
      });
      
  };
  handleSave = (obj) => {
    // this.setState({ submit: true });
    // const [form] = Form.useForm()
    // console.log(this.state.obj);
    axios
      .post("/kp/save_for_later", obj)
      .then((res) => {
        // this.setState({ submit: false });

        console.log(res.data);
        notification["success"]({
          message: "Submitted",
          description: `Data is saved successfully.`,
        });
        this.setState({ save: "validate"})
        // store.dispatch({ type: "PRINT", payload: false });
        // store.dispatch({ type: "success", payload: true });
      })
      .catch((err) => {
        console.log(err);
        store.dispatch({ type: "TF", payload: false });
        // notification["warning"]({
        //   message: "Submitted",
        //   description: `Your Document has been Already submitted.`,
        // });
        // this.setState({ submit: false });
      });
      
  };

  render() {
    const {
      verified,
      loading,
      loading2,
      obj,
      visible,
      dist,
      mobile,
      fireworksShow,
      auth,
      save,
      authenticating
    } = this.state;

    const { print, toggleForm, authentication } = store.getState().data;
    // const [form] = Form.useForm()
    console.log(toggleForm,print,auth)

    return (
      <>
        {(Object?.keys(auth)?.length === 2) ? <Row justify="center">
          {(!toggleForm) && (
            <Col
              xs={22}
              md={15}
              sm={15}
              lg={12}
              xl={8}
              style={{
                margin: 20,
              }}
              id="printJS-form"
            >
              {Object?.keys(obj)?.length>0 && <Form
                layout="vertical"
                ref={this.formRef}
                name="control-ref"
                initialValues={{ remember: true, ...obj }}
                onFinish={this.onFinish}
                // {...(save === "validate" && {
                //   onFinishFailed : () =>
                //   notification["warning"]({
                //     message: "All Fields Are Required !!!",
                //   })
                // })}
                // {...(save === "save" && {noValidate : true})}
                onFinishFailed={() =>
                  notification["warning"]({
                    message: "All Fields Are Required !!!",
                  })
                }
              >
                <div className="bg-white px-20 py-4 mt-10 shadow-xl">
                  <>
                    {console.log(obj)}
                  </>
                  <div className="w-full justify-center flex">
                    <img
                      src={require("../images/unnamed.jpg")}
                      className="lg:w-3/4 md:w-3/4 sm:w-3/4 w-full w my-6"
                    />
                  </div>
                </div>
                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Puja Committee
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name of Puja Committee"
                    rules={[
                      {
                        required: true,
                        message: "Name Required",
                      },
                    ]}
                    name="name"
                    initialValue={obj.name}
                    
                  >
                    <Input placeholder="Name" disabled={obj.name}/>
                  </Form.Item>
                  <Form.Item
                    label="Address Line 1"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 1 Required",
                      },
                      {
                        max: 100,
                        message: "Address Limit to 1-100 character",
                      },
                    ]}
                    name="puja_committee_line_one"
                    initialValue={obj?.puja_committee_line_one}
                  >
                    <Input placeholder="Address Line 1" disabled/>
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 2"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 2 Required",
                      },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="puja_committee_line_two"
                    initialValue={obj?.puja_committee_line_two}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item> */}

                  <Form.Item
                    label="PIN Code"
                    rules={[
                      {
                        required: true,
                        message: "PIN Code Required",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                      {
                        len: 6,
                        message: "PIN Code must be of 6 numbers."
                      }
                    ]}
                    name="pin"
                    initialValue={obj?.pin}
                  >
                    <Input placeholder="PIN Code" type="number"/>
                  </Form.Item>

                  <Form.Item
                    label="District/Commissionerate/Police District"
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: "District Required",
                      },
                    ]}
                    initialValue={obj?.district}
                  >
                    <Select
                      allowClear
                      showArrow
                      showSearch
                      onChange={this._dist}
                      disabled
                    >
                      {Object.keys(data).map((data, i) => (
                        <Option value={data} key={i}>
                          {data}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* {dist && (
                    <Form.Item
                      label="Police Station"
                      name="police_station"
                      rules={[
                        {
                          required: true,
                          message: "Police Station Required",
                        },
                      ]}
                      initialValue={obj.police_station}
                    >
                      <Select allowClear showSearch>
                        {data[dist].map((d, i) => (
                          <Option value={d} key={i}>
                            {d}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )} */}
                  <Form.Item
                    label="Ward No."
                    name="ward_no"
                    rules={[
                      {
                        max: 30,
                        message: "Ward No. Limit 1-30",
                      },
                      // {
                      //   pattern: /^[0-9]+$/,
                      //   message: "Only Numeric Value",
                      // },
                    ]}
                    initialValue={obj.ward_no}
                  >
                    <Input disabled placeholder="ward no." style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    label="Nearest Fire station"
                    name="nearest_fire_station"
                    initialValue={obj.nearest_fire_station}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Fire Station Required",
                      // },
                      (save === "validate") && {
                        required: true,
                        message: "Fire Station Required",
                      },
                      {
                        max: 50,
                        message: "Fire Station Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nearest Fire station"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nearest Hospital"
                    name="nearest_hospital"
                    initialValue={obj.nearest_hospital}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Hospital required",
                      },
                      {
                        max: 50,
                        message: "Hospital Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input 
                      placeholder="Nearest Hospital"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Type of the Puja (Community or Private)"
                    name="type_of_puja"
                    initialValue={obj.type_of_puja}
                    rules={[
                      {
                        required: true,
                        message: "Type Of Puja Required",
                      },
                    ]}
                  >
                    <Select disabled>
                      <Option value="Community">Community</Option>
                      <Option value="Private">Private</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Puja Name (Durga Puja /Kali Puja / Other Puja)"
                    name="puja_name"
                    initialValue={obj.puja_name}
                    rules={[
                      {
                        required: true,
                        message: "Puja Name Required",
                      },
                    ]}
                  >
                    <Select disabled>
                      <Option value="Durga Puja">Durga Puja</Option>

                      <Option value="Kali Puja">Kali Puja</Option>
                      <Option value="Other Puja">Other Puja</Option>
                    </Select>
                  </Form.Item>
                </div>

                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Name & Details of President/Secretary
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name"
                    name="president_secretary_name"
                    initialValue={obj.president_secretary_name}
                    rules={[
                      {
                        required: true,
                        message: "Name Required",
                      },
                      {
                        max: 50,
                        message: "Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input disabled style={{ width: "100%" }} placeholder="name" />
                  </Form.Item>
                  <Form.Item
                    label={`Father's Name`}
                    name="president_secretary_father_name"
                    initialValue={obj.president_secretary_father_name}
                    rules={[
                      {
                        required: true,
                        message: `Father's Name Required`,
                      },
                      {
                        max: 50,
                        message: "Fathers Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input disabled
                      style={{ width: "100%" }}
                      placeholder="Fathers name"
                    />
                  </Form.Item>
                  {/* <Form.Item
                    label="Age"
                    name="president_secretary_age"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Age Required",
                      // },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    initialValue={obj.president_secretary_age}
                  >
                    <Select placeholder="Age" showSearch>
                      {Array(100)
                        .fill()
                        .map((v, i) => (
                          <Option value={i + 1} key={i}>
                            {i + 1}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item> */}

                  <Form.Item
                    label="Address Line 1"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 1 Required",
                      },
                      {
                        max: 100,
                        message: "Address Limit to 1-100 character",
                      },
                    ]}
                    name="president_line_one"
                    initialValue={obj?.president_line_one}
                  >
                    <Input disabled placeholder="Address Line 1" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 2"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 2 Required",
                      },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="president_line_two"
                    initialValue={obj?.president_line_two}
                  >
                    <Input disabled placeholder="Address Line 2" />
                  </Form.Item> */}

                  <Form.Item
                    label="PIN Code"
                    rules={[
                      {
                        required: true,
                        message: "PIN Code Required",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                      {
                        len: 6,
                        message: "PIN Code must be of 6 numbers."
                      }
                    ]}
                    name="president_secretary_pin"
                    initialValue={obj?.president_secretary_pin}
                  >
                    <Input placeholder="PIN Code" type="number"/>
                  </Form.Item>

                  {/* <Form.Item
                    label="Occupation"
                    name="president_secretary_occupation"
                    initialValue={obj.president_secretary_occupation}
                    rules={[
                      // { required: true, message: "Occupation Required" },
                      {
                        max: 30,
                        message: "char limit max to 1-30",
                      },
                    ]}
                  >
                    <Input style={{ width: "100%" }} placeholder="Occupation" />
                  </Form.Item> */}
                  <Form.Item
                    label="Mobile No."
                    name="president_secretary_mobile"
                    initialValue={obj.president_secretary_mobile}
                    rules={[
                      {
                        required: true,
                        message: "Mobile No. Required",
                      },
                      {
                        max: 10,
                        message: "Mobile No. Limit 1-10 Digits",
                      },
                      { min: 10, message: "Mobile No. Must Be Of 10 Digits" },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    {/* <Search
                      enterButton="Send OTP"
                      allowClear
                      placeholder="Mobile No."
                      onSearch={(value) => this._phoneOtpSend(value)}
                      disabled={verified}
                      loading={loading2}
                    /> */}
                    <Input disabled style={{ width: "100%" }} placeholder="Mobile No." />
                  </Form.Item>
                  {/* {visible && !verified && (
                    <Form.Item
                      label={`Verify OTP (OTP has been sent to ${mobile})`}
                      rules={[
                        {
                          required: true,
                          message: "OTP Required",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Only Numeric Value",
                        },
                      ]}
                    >
                      <Search
                        enterButton="Verify 
													OTP"
                        allowClear
                        placeholder="Enter OTP"
                        onSearch={(value) => this._verifySend(value)}
                        disabled={verified}
                        loading={loading}
                      />
                    </Form.Item>
                  )} */}

                  <Form.Item
                    label="Whatsapp No."
                    name="president_secretary_wa_no"
                    initialValue={obj.president_secretary_wa_no}
                    rules={[
                      { required: true, message: "WhatsApp No. Required" },
                      {
                        max: 10,
                        message: "WhatsApp No. Limit to 1-10",
                      },
                      {
                        min: 10,
                        message: "WhatsApp No. Must Be Of 10 digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input disabled allowClear placeholder="Whatsapp No." />
                  </Form.Item>

                  <Form.Item
                    label="Email Id"
                    name="president_secretary_email"
                    initialValue={obj.president_secretary_email}
                    rules={[
                      {
                        required: true,
                        message: "Email ID Required",
                      },
                      {
                        pattern:
                          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                        message: "Wrong Email ID",
                      },
                    ]}
                  >
                    {/* <Search
                      enterButton="Send OTP"
                      allowClear
                      placeholder="Email ID"
                      onSearch={(value) => this._emailOtpSend(value)}
                      disabled={this.state.verified_email}
                      loading={this.state.loading3}
                    /> */}
                    <Input disabled allowClear placeholder="Email Id" />
                  </Form.Item>
                  {this.state.visible_email && !this.state.verified_email &&
                    (<Form.Item
                      label={`Verify OTP (OTP has been sent to ${this.state.email})`}
                      rules={[
                        {
                          required: true,
                          message: "OTP Required",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Only Numeric Value",
                        },
                      ]}
                    >
                      <Search
                        enterButton="Verify 
													OTP"
                        allowClear
                        placeholder="Enter OTP"
                        onSearch={(value) => this._verifyEmail(value)}
                        disabled={this.state.verified_email}
                        loading={loading}
                      />
                    </Form.Item>)
                  }
                  <b
                    style={{
                      fontSize: 9,
                      color: "#6b6b6b",
                      float: "left",
                      margin: 2,
                      fontWeight: 400,
                    }}
                  >
                    Disclaimer - Please enter valid email, your submission will
                    be emailed to you.
                  </b>
                </div>

                {/*	Details of the Idol Maker Field Value Start.
									Author: Biswarup Das. 
									Date: 4-08-2022. */}
                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Details of the Idol Maker:{" "}
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name of the Seller / Organization"
                    name="idol_maker_name"
                    initialValue={obj.idol_maker_name}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Name required",
                      },
                      {
                        max: 50,
                        message: "Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input placeholder="Name of the Seller / Organization" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 1"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "Address Line 1 Required",
                      // },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="idol_line_one"
                    initialValue={obj?.idol_line_one}
                  >
                    <Input placeholder="Address Line 1" />
                  </Form.Item>

                  <Form.Item
                    label="Address Line 2"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "Address Line 2 Required",
                      // },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="idol_line_two"
                    initialValue={obj?.idol_line_two}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item>

                  <Form.Item
                    label="PIN Code"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "PIN Code Required",
                      // },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    name="idol_maker_pin"
                    initialValue={obj?.idol_maker_pin}
                  >
                    <Input placeholder="PIN Code" />
                  </Form.Item> */}

                  <Form.Item
                    label="Mobile No."
                    name="idol_maker_mobile"
                    initialValue={obj.idol_maker_mobile}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Mobile No. Required",
                      },
                      {
                        max: 10,
                        message: "Mobile No. Limit to 1-10",
                      },
                      {
                        min: 10,
                        message: "Mobile No. Must Be Of 10 digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Mobile No." />
                  </Form.Item>

                  <Form.Item
                    label="WhatsApp No."
                    name="idol_maker_wa_no"
                    initialValue={obj.idol_maker_wa_no}
                    rules={[
                      {
                        max: 10,
                        message: "WhatsApp No. Limit to 1-10",
                      },
                      {
                        min: 10,
                        message: "WhatsApp No. Must Be Of 10 digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Whatsapp No." />
                  </Form.Item>

                  {/* <Form.Item
                    label="Email Id"
                    name="idol_maker_email"
                    initialValue={obj.idol_maker_email}
                    rules={[
                      {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                        message: "Wrong Email ID",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Email Id" />
                  </Form.Item>

                  <Form.Item
                    label="No. of Idols purchased from him"
                    name="idol_maker_purchase_count"
                    initialValue={obj.idol_maker_purchase_count}
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="No. of Idols purchased from him"
                    />
                  </Form.Item> */}
                </div>
                {/* Details of the Idol Maker End.
                 *********************************************************************************************************************** */}

                {/*	Details of CATERER Field Value Start.
									Author: Biswarup Das. 
									Date: 4-08-2022. */}
                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Details of CATERER:{" "}
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name of the   Seller / Organization"
                    name="caterer_name"
                    initialValue={obj.caterer_name}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Name required",
                      },
                      {
                        max: 50,
                        message: "Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input placeholder="Name of the   Seller / Organization" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 1"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 1 Required",
                      },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="caterer_line_one"
                    initialValue={obj?.caterer_line_one}
                  >
                    <Input placeholder="Address Line 1" />
                  </Form.Item>

                  <Form.Item
                    label="Address Line 2"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "Address Line 2 Required",
                      // },
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="caterer_line_two"
                    initialValue={obj?.caterer_line_two}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item>

                  <Form.Item
                    label="PIN Code"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "PIN Code Required",
                      // },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    name="caterer_pin"
                    initialValue={obj?.caterer_pin}
                  >
                    <Input placeholder="PIN Code" />
                  </Form.Item> */}

                  <Form.Item
                    label="Mobile No."
                    name="caterer_mobile"
                    initialValue={obj.caterer_mobile}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Mobile No. Required",
                      },
                      {
                        max: 10,
                        message: "Mobile No. Limit to 1-10",
                      },
                      {
                        min: 10,
                        message: "Mobile No. Must Be Of 10 digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Mobile No." />
                  </Form.Item>

                  <Form.Item
                    label="WhatsApp No."
                    name="caterer_wa_no"
                    initialValue={obj.caterer_wa_no}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "WhatsApp No. Required",
                      // },
                      {
                        max: 10,
                        message: "WhatsApp No. Limit to 1-10",
                      },
                      {
                        min: 10,
                        message: "WhatsApp No. Must Be Of 10 digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Whatsapp No." />
                  </Form.Item>

                  {/* <Form.Item
                    label="Email Id"
                    name="caterer_email"
                    initialValue={obj.caterer_email}
                    rules={[
                      {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                        message: "Wrong Email ID",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Email Id" />
                  </Form.Item> */}

                  {/* No Need This Portion for CATERER. */}

                  {/* <Form.Item
										label="No. of Idols purchased from him"
										name="caterer_purchase_count"
										initialValue={obj.caterer_purchase_count}
										rules={[
											{
												pattern: /^[0-9]+$/,
												message: "Only Numeric Value",
											},
										]}
									>
										<Input
											style={{ width: "100%" }}
											placeholder="No. of Idols purchased from him"
										/>
									</Form.Item> */}
                </div>
                {/* Details of CATERER End.
                 *********************************************************************************************************************** */}

                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Details of supplier of Sound System:
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name"
                    name="sound_system_supplier_name"
                    initialValue={obj.sound_system_supplier_name}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Name Required",
                      },
                      {
                        max: 50,
                        message: "Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 1 (optional)"
                    rules={[
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="sound_line_one"
                    initialValue={obj?.sound_line_one}
                  >
                    <Input placeholder="Address Line 1" />
                  </Form.Item>

                  <Form.Item
                    label="Address Line 2 (optional)"
                    rules={[
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="sound_line_two"
                    initialValue={obj?.sound_line_two}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item>

                  <Form.Item
                    label="PIN Code"
                    rules={[
                      // {
                      // 	required: true,
                      // 	message: "PIN Code Required",
                      // },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    name="sound_system_supplier_pin"
                    initialValue={obj?.sound_system_supplier_pin}
                  >
                    <Input placeholder="PIN Code" />
                  </Form.Item> */}

                  <Form.Item
                    label="Mobile No."
                    name="sound_system_supplier_mobile"
                    initialValue={obj.sound_system_supplier_mobile}
                    rules={[
                      { max: 10, message: "Mobile No. Limit 1-10 digits" },
                      { min: 10, message: "Mobile No. Must Be Of 10 digits" },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                      (save === "validate") && {
                        required: true,
                        message: "Mobile No. Required",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Mobile No." />
                  </Form.Item>

                  <Form.Item
                    label="Whatsapp No."
                    name="sound_system_supplier_wa_no"
                    initialValue={obj.sound_system_supplier_wa_no}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Whatsapp No. Required",
                      // },
                      {
                        max: 10,
                        message: "WhatsApp No. Limit to 1-10 Digits",
                      },
                      {
                        min: 10,
                        message: "WhatsApp No. Must Be Of 10 Digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Whatsapp No." />
                  </Form.Item>

                  {/* <Form.Item
                    label="Email Id"
                    name="sound_system_supplier_email"
                    initialValue={obj.sound_system_supplier_email}
                    rules={[
                      {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                        message: "Wrong Email ID",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Email Id" />
                  </Form.Item> */}
                  {/* <Form.Item
                    label="CIN No for Company/LLP "
                    name="sound_system_supplier_cin_no"
                    initialValue={obj.sound_system_supplier_cin_no}
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: "Only AlphaNumeric Value",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="CIN No for Company/LLP"
                    />
                  </Form.Item> */}
                  {/* <Form.Item
                    label="No of Amplifier(s) Supplied"
                    name="sound_system_supplier_amplifier_count"
                    initialValue={obj.sound_system_supplier_amplifier_count}
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="No of Amplifier(s) Supplied"
                    />
                  </Form.Item>
                  <Form.Item
                    label="No of Sound Limiters proposed to use"
                    name="sound_system_supplier_limiter_count"
                    initialValue={obj.sound_system_supplier_limiter_count}
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="No of Sound Limiters proposed to use"
                    />
                  </Form.Item> */}
                </div>
                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Details of Decorator
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Name"
                    name="decorator_name"
                    initialValue={obj.decorator_name}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "Name Required",
                      },
                      {
                        max: 50,
                        message: "Name Limit to 1-50 Character",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Address Line 1 (optional)"
                    rules={[
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="decorator_line_one"
                    initialValue={obj?.decorator_line_one}
                  >
                    <Input placeholder="Address Line 1" />
                  </Form.Item>

                  <Form.Item
                    label="Address Line 2  (optional)"
                    rules={[
                      {
                        max: 40,
                        message: "Address Limit to 1-40 character",
                      },
                    ]}
                    name="decorator_line_two"
                    initialValue={obj?.decorator_line_two}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item>
                  <Form.Item
                    label="PIN Code"
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    name="decorator_pin"
                    initialValue={obj?.decorator_pin}
                  >
                    <Input placeholder="PIN Code" />
                  </Form.Item> */}
                  <Form.Item
                    label="Mobile No."
                    name="decorator_mobile"
                    initialValue={obj.decorator_mobile}
                    rules={[
                      {
                        max: 10,
                        message: "Mobile No. Limit to 1-10 Digits",
                      },
                      {
                        min: 10,
                        message: "Mobile No. Must Be Of 10 Digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                      (save === "validate") && { required: true, message: "Mobile No. Required" },
                    ]}
                  >
                    <Input allowClear placeholder="Mobile No." />
                  </Form.Item>
                  <Form.Item
                    label="Whatsapp No."
                    name="decorator_wa_no"
                    initialValue={obj.decorator_wa_no}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "WhatsApp No. Required",
                      // },
                      {
                        max: 10,
                        message: "WhatsApp No. Limit to 1-10 Digits",
                      },
                      {
                        min: 10,
                        message: "WhatsApp No. Must Be Of 10 Digits",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Whatsapp No." />
                  </Form.Item>

                  {/* <Form.Item
                    label="Email Id"
                    name="decorator_email"
                    initialValue={obj.decorator_email}
                    rules={[
                      {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                        message: "Wrong Email ID",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Email Id" />
                  </Form.Item> */}
                  {/* <Form.Item
                    label="CIN No for Company/LLP "
                    name="decorator_cin"
                    initialValue={obj.decorator_cin}
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: "Only AlphaNumeric Value",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="CIN No for Company/LLP "
                    />
                  </Form.Item> */}
                </div>

                {/* <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Awards in last 3 years (If Any){" "}
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.List name="awards_in_last_three_years">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? "Awards" : ""}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "awards required",
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder="award name"
                                  style={{ width: "80%" }}
                                />
                              </Form.Item>
                              {fields.length >= 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  style={{ margin: "0 8px" }}
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              style={{ width: "60%" }}
                            >
                              Add Award
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                </div> */}

                <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Immersion details
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  {/* <Form.Item
                    label="Proposed Date"
                    name="immersion_proposed_date"
                    valuePropName="date"
                    initialValue={obj.immersion_proposed_date}
                    rules={[
                      {
                        required: true,
                        message: "date required",
                      },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} value={moment(obj.immersion_proposed_date)} disabled={obj.immersion_proposed_date}/>
                  </Form.Item>

                  <Form.Item
                    label="No of vehicle(s) to be used"
                    name="immersion_vehicle_count"
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only Numeric Value",
                      },
                    ]}
                    initialValue={obj.immersion_vehicle_count}
                  >
                    <Select showSearch>
                      {Array(100)
                        .fill()
                        .map((v, i) => (
                          <Option value={i + 1} key={1}>
                            {i + 1}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Name of the Ghat and City"
                    name="immersion_name_of_ghat_and_city"
                    initialValue={obj.immersion_name_of_ghat_and_city}
                  >
                    <Input allowClear placeholder="Name of the Ghat and city" />
                  </Form.Item> */}
                  {/* <Form.Item
                    label="Name of the agency for clearance of waste materials"
                    name="immersion_waste_clearance_agency_name"
                    initialValue={obj.immersion_waste_clearance_agency_name}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Name of the agency for clearance of waste materials"
                    />
                  </Form.Item> */}

                  <Form.Item
                    label="Whether fireworks will be used (Yes/No)"
                    name="immersion_fireworks_usage"
                    initialValue={obj.immersion_fireworks_usage}
                    rules={[
                      (save === "validate") && {
                        required: true,
                        message: "fireworks Usage Required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Yes/No"
                      onChange={(e) => this.setState({ fireworksShow: e })}
                    >
                      <Option value={"Yes"}>Yes</Option>
                      <Option value={"No"}>No</Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* {fireworksShow && (
                  <>
                    <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                      Details of supplier of Fireworks to be used{" "}
                    </p>
                    <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                      <Form.Item
                        label="Name "
                        name="fireworks_supplier_name"
                        initialValue={obj.fireworks_supplier_name}
                        rules={[
                          {
                            required: fireworksShow,
                            message: "Name Required",
                          },
                          { max: 50, message: "Name Limit to 1-50 Character" },
                        ]}
                      >
                        <Input style={{ width: "100%" }} placeholder="Name" />
                      </Form.Item>

                      <Form.Item
                        label="Address Line 1 (optional)"
                        rules={[
                          {
                            max: 40,
                            message: "Address Limit to 1-40 character",
                          },
                        ]}
                        name="supplier_line_one"
                        initialValue={obj?.supplier_line_one}
                      >
                        <Input placeholder="Address Line 1" />
                      </Form.Item>

                      <Form.Item
                        label="Address Line 2 (optional)"
                        rules={[
                          {
                            max: 40,
                            message: "Address Limit to 1-40 character",
                          },
                        ]}
                        name="supplier_line_two"
                        initialValue={obj?.supplier_line_two}
                      >
                        <Input placeholder="Address Line 2" />
                      </Form.Item>

                      <Form.Item
                        label="PIN Code"
                        rules={[
                          {
                            pattern: /^[0-9]+$/,
                            message: "Only Numeric Value",
                          },
                        ]}
                        name="fireworks_supplier_pin"
                        initialValue={obj?.fireworks_supplier_pin}
                      >
                        <Input placeholder="PIN Code" />
                      </Form.Item>

                      <Form.Item
                        label="Contact No."
                        //rules={[{ max: 10, message: "10 digits required" }]}
                        name="fireworks_supplier_mobile"
                        initialValue={obj.fireworks_supplier_mobile}
                        rules={[
                          {
                            max: 10,
                            message: "Contact No. Limit to 1-10 Digits",
                          },
                          {
                            min: 10,
                            message: "Contact No. Must Be Of 10 Digits",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Only Numeric Value",
                          },
                          {
                            required: fireworksShow,
                            message: "Contact No. Required",
                          },
                        ]}
                      >
                        <Input allowClear placeholder="Contact No." />
                      </Form.Item>

                      <Form.Item
                        label="Email Id"
                        name="fireworks_supplier_email"
                        initialValue={obj.fireworks_supplier_email}
                        rules={[
                          {
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                            message: "Wrong Email ID",
                          },
                        ]}
                      >
                        <Input allowClear placeholder="Email ID" />
                      </Form.Item>
                    </div>
                  </>
                )} */}

                {/* <p className="text-gray-700 font-medium text-left p-5 bg-gray-200">
                  Proposed Environment Protection Adoption
                </p>
                <div className="bg-white px-10 lg:px-20 py-8 shadow-xl">
                  <Form.Item
                    label="Wet waste separation and disposal"
                    name="wwsd"
                    //required= {true}
                    initialValue={obj.wwsd}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Dry waste separation and disposal"
                    name="dwsd"
                    initialValue={obj.dwsd}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Plastic waste separation and disposal"
                    name="pwsd"
                    initialValue={obj.pwsd}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="E- waste separation and disposal "
                    name="ewsd"
                    initialValue={obj.ewsd}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Bio-Medical waste separation and disposal"
                    name="bmwsd"
                    initialValue={obj.bwsd}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Awareness Message and banners (Yes/No)"
                    name="awareness_message_banners"
                    initialValue={obj.awareness_message_banners}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Name of the agency /Person managing waste management"
                    name="waste_management_agency"
                    initialValue={obj.waste_management_agency}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please provide name",
                    //   },
                    // ]}
                  >
                    <Input placeholder="Name of the agency /Person managing waste management" />
                  </Form.Item>

                  <Form.Item
                    label="Non-Bio-degradable materials used in decoration (Yes/No)"
                    name="mbdm_used_in_decoration"
                    initialValue={obj.mbdm_used_in_decoration}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Sufficient no. of waste disposal bins used in Pandal vicinity (Yes/No)"
                    name="suf_no_waste_bins"
                    initialValue={obj.suf_no_waste_bins}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Sanitization measures"
                    name="sanitization_measures"
                    initialValue={obj.sanitization_measures}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Social distancing measures"
                    name="social_distancing_measures"
                    initialValue={obj.social_distancing_measures}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Awareness about COVID-19"
                    name="covid19_awareness"
                    initialValue={obj.covid19_awareness}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Yes/No",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Yes/No">
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>
                </div> */}

                {print && (
                  <div className="mt-5">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" className="m-2 bg-blue-800 hover:bg-blue-400"
                      disabled={obj?.is_final_submit}
                       onClick={()=> this.setState({save: "save"})}
                      //  onClick={()=> {this.handleSave(); this.setState({obj: form.getFieldsValue()})}}
                       >
                        Save For Later
                      </Button>
                      <Button type="primary" htmlType="submit" disabled={obj?.is_final_submit} className="m-2" onClick={()=> this.setState({save : "validate"})}>
                        Next
                      </Button>
                      <Button htmlType="button" disabled={obj?.is_final_submit} className="m-2" onClick={this.onReset}>
                        Reset
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form>}
            </Col>
          )}

          {
          (toggleForm && Object?.keys(obj)?.length>0) &&
           (
            <Col
              xs={24}
              lg={12}
              style={{
                margin: 20,
                marginTop: 60,
              }}
              className="page-break"
            >
              <Form onFinish={this._sForm}>
                <div className="p-10 shadow-xl bg-white">
                  <div>
                    <div className="w-full justify-center flex">
                      <img
                        src={require("../images/unnamed.jpg")}
                        className="h-16 my-6"
                      />
                    </div>
                    <div className="w-full justify-center items-center flex">
                      <h1 className="text-lg font-bold">
                        BEFORE THE WEST BENGAL POLLUTION CONTROL BOARD
                      </h1>
                    </div>
                    <div className="w-full justify-center flex items-center">
                      <h5 className="font-medium text-xs w-3/5 ">
                        {" "}
                        “Paribesh Bhawan”, Bldg. No. 10A, LA Block, Sector-lll,
                        Bidhannagar, Kolkata- 700106. E-mail:
                        <a
                          href="net.wbpcb-wb@bangla.gov.in"
                          className="text-blue-400"
                        >
                          net.wbpcb-wb@bangla.gov.in
                        </a>
                        ; Website: www.wbpcb.gov.in
                      </h5>
                    </div>
                    <div className="w-full justify-center flex items-center">
                      <h1 className="text-xl mt-5">UNDERTAKING</h1>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: 30,
                      lineHeight: 2,
                      textAlign: "left",
                    }}
                    className="text-justify"
                  >
                    I ,{" "}
                    <b className="form2">
                      {obj?.president_secretary_name || "persident name"}
                    </b>{" "}
                    &nbsp; son/daughter/wife of &nbsp;
                    <b className="form2">
                      {obj?.president_secretary_father_name || "fathers name"}
                    </b>
                    &nbsp; residing at &nbsp;
                    <b className="form2">
                      {obj?.president_line_one ||
                        "president seceretary address"}
                    </b>
                    &nbsp; do hereby undertake as follows:
                    <div
                      style={{
                        marginTop: 40,
                        color: "#222",
                        fontSize: 14,
                      }}
                      className="text-justify"
                    >
                      <b className="text-lg font-medium text-gray-700">1)</b>{" "}
                      That, I am the President/Secretary of &nbsp;
                      <b className="form2">
                        {obj?.name || "name of the person"}
                      </b>
                      &nbsp; (name of Puja Committee / Barwari) having it’s
                      address at &nbsp;
                      <b className="form2">
                        {obj?.puja_committee_line_one  ||
                          "venua and pin code"}
                      </b>
                      &nbsp; and organizing a community {" "}
                      <b className="form2">
                        {obj?.puja_name || "name of the person"}
                      </b>
                      &nbsp;at{" "}
                      <b className="form2">
                        {obj?.puja_committee_line_one }
                      </b>
                      &nbsp;in the year&nbsp;
                      <b className="form2">{moment().format("YYYY")}</b>
                    </div>
                    <div className="text-sm text-gray-900 mt-16 text-justify">
                      <b className="text-lg font-medium text-gray-700">2)</b>{" "}
                      That, I, on behalf of my organization as mentioned above,
                      undertake that my organization will strictly comply with
                      the following: -
                      <ol className="list-roman text-sm  text-gray-700 pl-5"
                       type="I"
                       >
                        <li>
                          In conformity with order dated 06.10.2017 of the
                          Hon’ble National Green Tribunal, Eastern Zone, passed
                          in connection with OA No: 51/2016 (Ambar Nath Sengupta
                          Vs State of West Bengal & Ors.) and letter of the
                          Central Pollution Control Board (CPCB), Delhi dated
                          17/02/2022 for effective implementation of the
                          “Revised Guidelines for Idol Immersion, May 2020”; ){" "}
                          <ol
                            type="a"
                            className="list-lowercase pl-8 text-gray-900 text-xs font-medium"
                          >
                            <li>
                              Making of Idols to be done using clay and
                              biodegradable materials only;
                            </li>
                            <li>
                              No Plaster of Paris will be used in Idol making.
                            </li>
                            <li>
                              The use of natural dyes/colours and biodegradable
                              materials is to be explored for decoration of
                              idols;
                            </li>
                            <li>
                              No synthetic paint or colour containing toxic
                              heavy metals like mercury, cadmium, arsenic, lead
                              and chromium shall be used in Idol making.
                            </li>
                            <li>The height of idol to be limited to 20 ft.</li>
                            <li>
                              The height of the super structure to be limited to
                              40ft.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Provisions of the West Bengal Prevention and Control
                          of Water Pollution (Procedure for Immersion of Idol
                          after Pujas) Rules, 2018.
                        </li>
                        <li>
                          Microphones to be used or operated fixing ‘Sound
                          Limiter’ with the amplifier system in accordance with
                          the order passed by the Hon’ble High Court, Calcutta,
                          in connection with matter No. 4303 (W) of 1995 (Om
                          Birangana Religious Society, Petitioner – Versus -
                          State of West Bengal & Ors., Respondents), as per
                          Order No. 1853 – 4K-5/2004 dated 27.08.2004 of the
                          State Board, and subsequent Notification Nos.
                          EN/238/3C-65/2019(Part-1) & EN/507/3C-65/2019(Part-1)
                          dated 03/02/2020 & 04/03/2020 respectively of the
                          Department of Environment, Govt. of West Bengal
                          regarding usage of audio systems/microphones fitted
                          with sound limiting device by manufacturers and
                          traders of audio systems/microphones and orders passed
                          by the Hon’ble National Green Tribunal in connection
                          with OA No.158/2016/EZ from time to time, for
                          maintaining ambient noise standard as specified for
                          different areas as per the relevant Acts and Rules.
                        </li>

                        <li>
                          Norms, as fixed up by the West Bengal Pollution
                          Control Board, for organizing a community Durga Puja / Kali Puja 2022 in accordance with
                          Environment (Protection) Act, 1986 read with the Noise
                          Pollution (Regulation & Control) Rules, 2000 and
                          subsequent amendments till date, which are:
                          <ol
                            className="list-lowercase pl-8 text-gray-900 text-xs font-medium"
                            type="a"
                          >
                            <li>
                              Microphones to be used and operated after
                              obtaining permission from the concerned police
                              authorities or district authorities and to abide
                              by all the terms and conditions fixed up by the
                              police authorities or the district authorities at
                              the time of granting such permission.
                            </li>

                            <li>
                              No microphone to be used or operated within
                              ‘Silence Zone’ areas, i.e. within 100 meters of
                              any educational institution, hospital, nursing
                              home or court areas in accordance with the Acts
                              and Rules as mentioned above. (During holidays and
                              on working days after office hours, the areas of
                              educational institutions and courts are not
                              treated as ‘Silence Zones’).
                            </li>

                            <li>
                              That, no noise making fireworks, which generates
                              noise more than 90 dB(A) impulse from a distance
                              of 5 meter from the point of bursting shall be
                              used.{" "}
                            </li>

                            <li>
                              No Disk Jockey Set (D.J) will be used during the
                              procession of immersion of idols.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Order of the State Board dated 26/10/2021 issued in
                          compliance with the Hon’ble Supreme Court orders vide
                          judgement dated 23/10/2018 in W.P (C) No.728 of 2015,
                          directions of the Hon’ble National Green Tribunal in
                          O.A. No 249/2020 dated 09/11/2020 and 01/12/2020, by
                          ensuring complete ban on the sale and bursting of all
                          kinds of firecrackers, except green crackers. Only
                          green crackers are to be sold and bursting of such
                          crackers shall be allowed for (i) two hours during
                          Deepawali festival from 8 p.m. to 10 p.m. (ii) two
                          hours during Chatt Puja from 6 a.m. to 8 a.m. (iii) 35
                          minutes during Christmas & New years’ Eve from 11:55
                          p.m. to 12:30 a.m.
                          <br /> Other than the above specified festivals, prior
                          permission of the District Magistrates/Commissioners
                          of Police/Superintendents of Police shall be taken for
                          use of only green crackers for a limited period (not
                          more than two hours).
                        </li>
                        <li>
                          Provisions of the Plastic Waste Management Rules,2016,
                          as amended, including abiding with the following
                          directions:
                          <ol
                            // class=" list-inside pl-8 text-gray-900 text-xs font-medium"
                            className="list-lowercase pl-8 text-gray-900 text-xs font-medium"
                            type="a"
                          >
                            <li>
                              No polystyrene (thermocol) to be used for
                              decoration;
                            </li>
                            <li>
                              No plastic or PVC banners less than 100 microns to
                              be used.
                            </li>
                            {/* Also, I shall ensure compliance of the following
                            norms strictly, during the immersion process:
                            <li>
                              The offerings, like flowers and leaves etc. will
                              be deposited in the Bins, Places or Pits as
                              arranged by the concerned local authorities on the
                              banks of rivers, ponds, water bodies.
                            </li>
                            <li>
                              Immersion of idols will take place in accordance
                              with the scheduled dates fixed up by the Police
                              Authorities or by the District Authorities as the
                              case may be.
                            </li> */}
                          </ol>
                        </li>

                        {/* <li>
                          Microphones shall be used or operated only fixing
                          ‘Sound Limiter’ with the amplifier system in
                          accordance with the order passed by the Hon’ble High
                          Court, Calcutta, in connection with matter No. 4303
                          (W) of 1995 (Om Birangana Religious Society,
                          Petitioner – Versus - State of West Bengal & Ors.,
                          Respondents), order of the State Board dated-
                          27.08.2004, and orders passed by the Hon’ble National
                          green Tribunal in connection with OA No.158/2016/EZ,
                          for maintaining ambient noise standard as specified
                          for different areas as per the aforementioned Acts and
                          Rules.
                        </li> */}

                        {/* <li>
                          Provisions of the West Bengal Prevention and Control
                          of Water Pollution (Procedure for Immersion of Idol
                          after Pujas) Rules, 2018. That the following norms
                          will be complied with strictly, during the immersion
                          process:
                          <ol class="list-roman list-inside pl-8 text-gray-900 text-xs font-medium">
                            <li>
                              The offerings, like flowers and leaves etc. will
                              be deposited in the Bins, Places or Pits as
                              arranged by the concerned local authorities.
                            </li>
                            <li>
                              Immersion of idols will take place in accordance
                              with the scheduled dates fixed up by the Police
                              Authorities or by the District Authorities as the
                              case may be.
                            </li>
                          </ol>
                        </li> */}
                      </ol>
                      <div className="text-sm text-medium mt-8 text-justify">
                        <b className="text-lg font-medium text-gray-700"></b>{" "}
                        Also, I shall ensure compliance of the following norms
                        strictly, during the immersion process:
                        <ol  className="list-roman pl-8 text-gray-900 text-xs font-medium">
                            <li>
                              The offerings, like flowers and leaves etc. will
                              be deposited in the Bins, Places or Pits as
                              arranged by the concerned local authorities on the
                              banks of rivers, ponds, water bodies.
                            </li>
                            <li>
                              Immersion of idols will take place in accordance
                              with the scheduled dates fixed up by the Police
                              Authorities or by the District Authorities as the
                              case may be.
                            </li>
                        </ol>
                      </div>
                    </div>
                    <div className="text-sm text-medium mt-8 text-justify">
                      <b className="text-lg font-medium text-gray-700"></b>{" "}
                      Further, I have no objection to the use of the WhatsApp
                      phone numbers of President/Secretary, Idol Maker, Sound
                      System Supplier, Decorator whose details have been
                      provided in this GREEN PUJA form by West Bengal Pollution
                      Control Board.
                    </div>
                    <div className="text-sm text-medium mt-8 text-justify">
                      <b className="text-lg font-medium text-gray-700"></b> I
                      hereby declare that the details furnished above are true
                      and correct to the best of my knowledge and belief and I
                      undertake to inform you of any changes therein,
                      immediately. In case any of the above information is found
                      to be false or untrue or misleading or misrepresenting, I
                      am aware that I may be held legally liable for it.
                    </div>
                    <div className="text-sm text-medium mt-4 text-justify">
                      <b className="text-lg font-medium text-gray-700"></b> I
                      hereby also declare that the WBPCB GREEN PUJA online form
                      at the following link{" "}
                      <a href="https://kolkata-greenpuja.wbpcb.gov.in/" target="_blank">
                        <u>https://kolkata-greenpuja.wbpcb.gov.in/</u>
                      </a>{" "}
                      shall be submitted within 10th October 2024.
                    </div>
                    <div className="text-sm text-medium mt-4 text-justify">
                      <b className="text-lg font-medium text-gray-700"></b>{" "}
                      That in case of violation of any of the above-mentioned
                      norms, the West Bengal Pollution Control Board and other
                      authorities concerned under the statute shall be at
                      liberty to take necessary penal action against the
                      community puja committee in accordance with law, by
                      imposing responsibility upon the President/Secretary of
                      the Puja Committee.
                    </div>
                  </div>

                  <div style={{ width: "100%"}}>
                    <div style={{ background: "#fff", width: "40%" }}></div>

                    <div
                      style={{
                        // width: "60%",
                        textAlign: "right",
                        alignItems: "right"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          textAlign: "right",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "40%"}}>Signature with date</div>
                        <div style={{ width: "55%", textAlign: "center" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.president_secretary_name +
                              " " +
                              moment().format("ll")}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          textAlign: "right",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>Seal:</div>
                        <div style={{ width: "60%", textAlign: "justify" }}>
                          {/* <span className="btm" role="textbox" contenteditable>
                            {}
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div
                      style={{
                        width: "60%",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>Address </div>
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.president_line_one || "null"}
                          </span>
                        </div>
                      </div>

                      {/* <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>P.S</div>
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.police_station || "null"}
                          </span>
                        </div>
                      </div> */}

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>District Commissionerate:</div>
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.district || "null"} Police
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>Pin:</div> 
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.president_secretary_pin || "null"}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>Mob No </div>
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span className="btm" role="textbox" contenteditable>
                            {obj?.president_secretary_mobile || "null"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginBottom: 12,
                        }}
                      >
                        <div style={{ width: "30%" }}>E-mail </div>
                        <div style={{ width: "55%", textAlign: "justify" }}>
                          <span
                            className="btm lowercase"
                            role="textbox"
                            contenteditable
                          >
                            {obj?.president_secretary_email || "null"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Should agree"),
                      },
                    ]}
                    className="mt-8"
                  >
                    <Checkbox>I agree</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.submit}
                      onClick={this.handlePrint}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          )}
          {/* <div className="">
            <FormPdf data={this.state.obj} ref={this.myRef} />
          </div> */}
        </Row> : !authenticating ? <Modal
        title="Authentication failed..."
        centered
        visible={!authentication && !authenticating}
        bodyStyle={{ background: "#fcffffd6" }}
      >
        <div className="flex">
          <Result
            status="error"
            title={
              <>
                <p className="text-3xl">Sorry!!!</p>
                <p className="text-xl">
                  You are not authenticated to access this form.
                </p>
              </>
            }
          />
        </div>
      </Modal> : 
      <Space wrap className="flex flex-col justify-center gap-5 items-center flex-1 h-screen">
        <Spin/>
        <p className="font-bold text-xl">Loading...</p>
      </Space>
      }{" "}
      </>
    );
  }
}

export default function Main() {
  const componentRef = useRef();
  // const { authentication } = store.getState().data;
  const { success, authentication } = useSelector((state) => state.data);
  console.log(authentication)
  const [data,setData] = useState({})
  const [print,setPrint] = useState(false)
  const [showModal,setShowModal] = useState(authentication && authentication)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page{size: A4; margin: 200mm !important}"
    //onAfterPrint: () => setPrint(!print)
  });
  // useEffect(()=>{
  //   authentication && setShowModal(authentication)
  // },[authentication])

  const clickHandler = (data) =>{
    setData(data)
  }
  //console.log(data)
  // const printHandler = (data) =>{
  //   setPrint(data)
  // }
  console.log(showModal)

  if(success){
    handlePrint();
  }

  const cancel = () => {
    store.dispatch({ type: "success", payload: false });
    store.dispatch({ type: "PRINT", payload: true });
    store.dispatch({ type: "TF", payload: false });
    store.dispatch({ type: "RESET", payload: true });
    setTimeout(() => {
      store.dispatch({ type: "RESET", payload: false });
    }, 1000);
  };

  const cancelHandler = () => {
    window.location.reload();
  }
  const modalHandler = (e) => {
    e.preventDefault();
    setShowModal(!showModal)
  }

  return (
    <Layout className="layout">
      <div className="w-full h-20 bg-white flex justify-start items-center shadow-sm fixed z-10">
        <div className="w-1/4">
          <img
            src={require("../images/unnamed.jpg")}
            className="border border-solid border-gray-200 h-16 lg:w-3/4 md:w-full sm:w-full w-3/4 ml-8"
          />
        </div>
        <div className="flex justify-center items-center w-2/4">
          <h1 className="lg:text-3xl md:text-3xl sm:text-2xl text-lg text-gray-700">
            Green Puja Form
          </h1>
        </div>
        <div className="flex flex-wrap flex-col justify-end items-center w-1/4 mr-2 my-1">
          <b className="2xl:text-base lg:text-base md:text-sm text-xs mb-1 ">
            For any support, please call-{" "}
          </b>
          <div className="flex justify-center items-center">
            <img
              src={require("../images/call.svg")}
              className="lg:h-5 h-4 hidden sm:flex"
            />
            <b className="text-blue-700 2xl:text-lg lg:text-lg xs:text-xs text-xs">
              1800-345-3390
            </b>
          </div>
        </div>
      </div>
      <Content style={{ background: "#f7f7f7", marginTop: 40 }}>
        <img
          src={require("../images/bg.jpg")}
          className="w-full h-full opacity-25 fixed object-cover"
          style={{ filter: "blur(16px)" }}
        />
        <FormComp onClick={clickHandler} />{" "}
        <div className="hidden">
          <FormPdf data={data} ref={componentRef} />
        </div>
      </Content>

      <Modal
        title="Download Pdf"
        centered
        visible={success}
        bodyStyle={{ background: "#fcffffd6" }}
      >
        <div className="flex">
          <Result
            status="success"
            title={
              <>
                <p className="text-3xl">Thank You!!!</p>
                <p className="text-xl">
                  Your information has been submitted successfully. Please check
                  your email for confirmation.
                </p>
                <Button key="console" onClick={cancelHandler}>
                  Cancel
                </Button>
                {/* <button onClick={handlePrint}>Print</button> */}
                {/* <ReactToPrint
                trigger={()=> <a href="#">Print this form</a>}
                content = {()=> <Form data={}/>}
                /> */}
              </>
            }
            // subTitle={
            // 	<div className="flex justify-center items-center mt-4">
            // 		<FilePdfOutlined style={{ color: "red", fontSize: 30 }} />
            // 		<p className="text-gray-900 ml-4 font-medium text-sm">
            // 			Download Pdf file of form.(Recommended)
            // 		</p>
            // 	</div>
            // }
            // extra={[
            //   <Button key="console" onClick={cancel}>
            //     Cancel
            //   </Button>,
            //   <Button
            //   	key="buy"
            //   	onClick={() => {
            //   		handlePrint();
            //   		cancel();
            //   	}}
            //   	type="primary"
            //   >
            //   	Download Pdf
            //   </Button>
            // ]}
          />
        </div>
      </Modal>
      <Modal
        title="Notice"
        centered
        visible={showModal}
        bodyStyle={{ background: "#fcffffd6" }}
        onCancel={modalHandler}
      >
        <div className="flex flex-col">
          <div className="flex justify-end">
            <img
              src={require("../images/cross.svg")}
              className="h-6 cursor-pointer hover:opacity-50"
              onClick={modalHandler}
            />
          </div>
          <Result
            icon={
              <div className="flex justify-center">
                <img src={require("../images/unnamed.jpg")} className="h-16" />
              </div>
            }
            className="py-2 px-2"
            title={
              <>
                <div>
                  {/* <div className="text-base whitespace-pre-wrap font-medium mb-3">
                    LAST CHANCE FOR DURGA PUJA COMMITTEE TO COMPLETE
                    REGISTRATION BEFORE 10TH OCTOBER 2024.
                  </div> */}
                  <div className="text-base whitespace-pre-wrap font-medium mb-3">
                    LAST CHANCE FOR KALI PUJA COMMITTEE TO COMPLETE REGISTRATION
                    BEFORE 29th OCTOBER 2024.
                  </div>
                  {/* <p className="text-base whitespace-pre-wrap font-medium mb-3">
                  <b>2.</b> LAST CHANCE FOR DURGA PUJA COMMITTE TO ALSO COMPLETE REGISTRATION BEFORE 20TH OCTOBER 2022. 
                  </p> */}
                  {/* <Button key="console" onClick={modalHandler}>
                  Close
                </Button> */}
                </div>
              </>
            }
          />
        </div>
      </Modal>

      {authentication && (
        <Footer className="lg:text-sm md:text-sm sm:text-sm text-xs font-medium">
          <p>West Bengal Pollution Control Board</p>
          Paribesh Bhavan, 10A, Block-L.A., Sector |||, Salt Lake City, Calcutta
          - 700 106, e-mail: wbpcbnet@wbpcb.gov.in, Call us at 1-800-345-3390
          (toll free)
        </Footer>
      )}
    </Layout>
  );
}
