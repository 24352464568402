export const data = {
	Alipurduar: [
		"Alipurduar",
		"Alipurduar Cyber Crime",
		"Alipurduar Women",
		"Birpara",
		"Falakata",
		"Jaigaon",
		"Kalachini",
		"Kumargram",
		"Madarihat",
		"Samuktala",
	],
	"Asansol Durgapur Police Commissionerate": [
		"Andal",
		"Asansol Durgapur Cyber Crime",
		"Asansol Women",
		"Asansole North",
		"Asansole South",
		"Barabani",
		"Budbud",
		"Chittaranjan",
		"Cokeoven",
		"Durgapur",
		"Durgapur Women",
		"Faridpur",
		"Hirapur",
		"Jamuria",
		"Kanksa",
		"Kulti",
		"New Township",
		"Pandabeshwar",
		"Raniganj",
		"Salanpur",
	],
	Bankura: [
		"Salanpur",
		"Bankura",
		"Bankura Cyber Crime",
		"Bankura Women",
		"Barikul",
		"Barjora",
		"Beliatore",
		"Bishnupur",
		"Chhatna",
		"Gangalghati",
		"Hirbandh",
		"Indpur",
		"Indus",
		"Joypur",
		"Khatra",
		"Khatra Women",
		"Katulpur",
		"Mejia",
		"Onda",
		"Patrasayer",
		"Raipur",
		"Ranibandh",
		"Saltora",
		"Sarenga",
		"Simlapal",
		"Sonamukhi",
		"Taldangra",
	],
	"Barasat Police District": [
		"Amdanga",
		"Ashokenagar",
		"Barasat",
		"Barasat PD Cyber Crime",
		"Barasat Women",
		"Deganga",
		"Duttapukur",
		"GOBARDANGA",
		"Habra",
		"Madhyamgram",
		"Shasan",
		"IC CYBER"
	],
	"Barrackpore Police Commissionerate": [
		"Baranagar",
		"Barrackpur",
		"Barrackpore Cyber Crime",
		"Barrackpore Women",
		"Basudebpur",
		"Belgharia",
		"Bhatpara",
		"Bizpur",
		"Dakshineswar",
		"Dumdum",
		"Ghola",
		"Halisahar",
		"Jagaddal",
		"Jetia",
		"Kamarhati",
		"Khardah",
		"Mohanpur",
		"Nagerbazar",
		"Naihati",
		"New Barrackpore",
		"Nimta",
		"Noapara",
		"OC Cyber Crime / SOG",
		"Rahara",
		"Shibdaspur",
		"Titagarh"		
	],
	"Baruipur Police District": [
		"Bakultala",
		"Baruipur",
		"Baruipur PD Cyber Crime",
		"Baruipur Women",
		"Basanti",
		"Bhangore",
		"Canning",
		"Canning Women",
		"Gosaba",
		"Haldibari(Sundarban) Coastal",
		"Jharkhali Coastal",
		"Jibantala",
		"Joynagar",
		"Kashipore",
		"Kultali",
		"Maipith Coastal",
		"Narendrapur",
		"Sonarpur",
	],
	"Basirhat Police District": [
		"Baduria",
		"Basirhat",
		"Bashirhat PD Cyber Crime",
		"Haroa",
		"Hasnabad",
		"Hemnagar Coastal",
		"Hingalganj",
		"Matia",
		"Minakhan",
		"Nazat",
		"Sandeshkhali",
		"Swarupnagar",
	],
	"Bidhannagar Police Commissionerate": [
		"Airport",
		"Baguiathi",
		"Bidhannagar Cyber Crime",
		"Bidhannagar East",
		"Bidhannagar North",
		"Bidhannagar South",
		"Bidhannagar Women",
		"Electronics Complex",
		"Lake Town",
		"Narayanpur",
		"New Town Eco Park",
		"New Town",
		"NSCBI Airport",
		"Rajarhat",
		"Technocity",
	],
	Birbhum: [
		"Birbhum Cyber Crime",
		"Bolpur",
		"BOLPUR WOMEN",
		"Chandrapur",
		"Dubrajpur",
		"Illambazar",
		"Kankartala",
		"Khoyrasole",
		"Kirnahar",
		"Labpur",
		"Lokpur",
		"Margram",
		"Mayureswar",
		"Mohammad Bazar",
		"Mollarpur",
		"Murarai",
		"Nalhati",
		"Nanoor",
		"Paikar",
		"Panrui",
		"Rajnagar",
		"Rampurhat",
		"Sadaipur",
		"Sainthia",
		"Santiniketan",
		"Suri",
		"Suri Women",
		"Tarapith",
	],
	"Bangaon Police District": [
		"Bagdha",
		"Bongaon",
		"Bongaon Women",
		"Gaighata",
		"Gopalnagar",
		"Petrapol",
	],
	"Chandannagar Police Commissionerate": [
		"Bhadreswar",
		"Chandernagore",
		"Chinsurah",
		"Chinsurah Women",
		"Dankuni",
		"Rishra",
		"Shrirampur Women",
		"Serampore",
		"Uttarpara",
	],
	"Cid Wb": ["Cyber Crime PS, CID, WB"],
	Coochbehar: [
		"Baxirhat",
		"Coochbehar Kotwali",
		"Coochbehar Cyber Crime",
		"Coochbehar Women",
		"Dinhata",
		"Dinhata Women",
		"Ghoksadanga",
		"Haldibari",
		"Kuchlibari",
		"Mathabhanga",
		"Mekhliganj",
		"Pundibari",
		"Sahebganj",
		"Sitai",
		"Sitalkuchi",
		"Tufanganj",
	],
	"Dakhsin Dinajpur": [
		"Balurghat",
		"Balurghat Women",
		"Bansihari",
		"Dakhsin Dinajpur Cyber Crime",
		"Gangarampur",
		"Harirampur",
		"Hili",
		"Kumarganj",
		"Kushmandi",
		"Patiram",
		"Tapan",
	],
	Darjeeling: [
		"Darjeeling",
		"Darjeeling Cyber Crime",
		"Darjeeling Women",
		"Jorbunglow",
		"Khoribari",
		"Kurseong",
		"Lodhoma",
		"Mirik",
		"Naxalbari",
		"Phanshidewa",
		"Pul Bazar",
		"Rangli Rangliot",
		"Sukhiapokhri",
	],
	"Diamond Harbour Police District": [
		"Bishnupur",
		"Budge Budge",
		"Diamond Harbour",
		"Diamond Harbour PD Cyber Crime",
		"Diamond Harbour Women",
		"Falta",
		"Kalitala Asuti",
		"Maheshtala",
		"Mograhat",
		"Nodakhali",
		"Parulia Coastal",
		"Pujali",
		"Rabindranagar",
		"Ramnagar",
		"Usthi",
	],
	"Hooghly Rural": [
		"Arambagh",
		"Arambagh Women",
		"Balagarh",
		"Chanditala",
		"Dadpur",
		"Dhaniakhali",
		"Goghat",
		"Gurap",
		"Haripal",
		"Hooghly Rural Cyber Crime",
		"Jangipara",
		"Khanakul",
		"Mogra",
		"Pandua",
		"Polba",
		"Pursurah",
		"Singur",
		"Tarakeswar",
	],
	"Howrah G.R.P.": [
		"Andal G.R.P.S.",
		"Asansole G.R.P.S.",
		"Azimganj G.R.P.S.",
		"Bandel G.R.P.S.",
		"Belur G.R.P.S.",
		"Burdwan G.R.P.S.",
		"Howrah G.R.P.S.",
		"Kalna G.R.P.S.",
		"Kamar Kundu G.R.P.S.",
		"Katwa G.R.P.S.",
		"Sainthia G.R.P.S.",
		"Sheoraphuli G.R.P.S.",
		"Suri G.R.P.S.",
	],
	"Howrah Police Commissionerate": [
		"Ajc Bose Botanic Garden",
		"Bally",
		"Bantra",
		"Belur",
		"Chatterjeehat",
		"Dasnagar",
		"Domjur",
		"Golabari",
		"Howrah",
		"Howrah Cyber Crime",
		"Howrah Women",
		"Jagacha",
		"Liluah",
		"Malipanchghara",
		"Nischinda",
		"Sankrail",
		"Santragachi",
		"Shibpur",
	],
	"Howrah Rural": [
		"Amta",
		"Bagnan",
		"Bauria",
		"Howrah Rural Cyber Crime",
		"Jagatballavpur",
		"Joypur",
		"Panchla",
		"Penro",
		"Rajapur",
		"Shyampur",
		"Udaynarayanpur",
		"Uluberia",
		"Uluberia Women",
	],
	"Islampur Police District": [
		"Chakulia",
		"Chopra",
		"Dalkhola",
		"Goalpokhar",
		"Islampur",
	],
	Jalpaiguri: [
		"Banarhat",
		"Dhubguri",
		"Kotwali",
		"Jalpaiguri",
		"Jalpaiguri Cyber Crime",
		"Jalpaiguri Women",
		"Mal Bazar",
		"Maynaguri",
		"Meteli",
		"Nagrakata",
		"Rajganj",
	],
	"Jangipur Police District": [
		"Farakka",
		"Jangipur Women",
		"Raghunathganj",
		"Sagardighi",
		"Samsherganj",
		"Suti",
	],
	Jhargram: [
		"Beliabera",
		"Belpahari",
		"Binpur",
		"Gopiballavpur",
		"Jamboni",
		"Jhargram",
		"Jhargram Cyber Crime",
		"Jhargram Women",
		"Lalgarh",
		"Nayagram",
		"Sankrail",
	],
	Kalimpong: [
		"Garubathan",
		"Jaldhaka",
		"Kalimpong",
		"Kalimpong Cyber Crime",
		"KALIMPONG WOMEN",
	],
	"Kharagpur G.R.P.": [
		"Adra G.R.P.S.",
		"Bankura G.R.P.S.",
		"Digha G.R.P.S.",
		"Haldia G.R.P.S.",
		"Jhargram G.R.P.S.",
		"Kharagpur G.R.P.S.",
		"Panskura G.R.P.S.",
		"Purulia G.R.P.S.",
		"Shalimar G.R.P.S.",
		"Uluberia G.R.P.S.",
	],
	"Krishnanagar Police District": [
		"BHIMPUR",
		"Chapra",
		"Dhubulia",
		"Hogalberia",
		"Kaliganj",
		"Karimpur",
		"Kotwali",
		"Krishnaganj",
		"Krishnanagar",
		"Krishnanagar Women",
		"Murutia",
		"Nabadwip",
		"Nadia Cyber Crime",
		"Nakashipara",
		"Palashipara",
		"Tehatta",
		"Thanarpara",
	],
	Malda: [
		"Baisnabnagar",
		"Bamongola",
		"Bhutni",
		"Chanchal",
		"English Bazar",
		"English Bazar Women",
		"Gazole",
		"Habibpur",
		"Harishchandrapur",
		"Kaliachak",
		"Malda",
		"Manickchak",
		"Mothabari",
		"Oldmalda",
		"Pukhuria",
		"Ratua",
	],
	"Murshidabad Police District": [
		"Beldanga",
		"Berhampore",
		"Berhampur Women",
		"Bhagawangola",
		"Bharatpur",
		"Burwan",
		"Daulatabad",
		"Domkal",
		"Hariharpara",
		"Islampur",
		"Jalangi",
		"Jiaganj",
		"Kandi",
		"Khargram",
		"Lalgola",
		"Murshidabad",
		"Murshidabad Cyber Crime",
		"Nabagram",
		"Nowda",
		"Raninagar",
		"Ranitala",
		"Rejinagar",
		"Sagarpara",
		"Sakitipur",
		"Salar",
	],
	"Paschim Medinipore": [
		"Anandapur",
		"Belda",
		"Chandrakona",
		"Dantan",
		"Daspur",
		"Debra",
		"Garhbeta",
		"Ghatal",
		"Goaltore",
		"Gurguripal",
		"Keshiary",
		"Keshpur",
		"Kharagpore Town",
		"Kharagpur Local",
		"Kharagpur Women",
		"Midnapore",
		"Mohanpur",
		"Narayangarh",
		"Pachim Medinipur Women",
		"Paschim Medinipore Cyber Crime",
		"Pingla",
		"Sabaong",
		"Salboni",
	],
	"Purba Bardhaman": [
		"Ausgram",
		"Bardhaman",
		"Bhatar",
		"Burdwan Kotwali",
		"Burdwan Women",
		"Dewandighi",
		"Galsi",
		"Jamalpur",
		"Kalna",
		"Kanksha",
		"Katwa",
		"Ketugram",
		"Khandaghosh",
		"Madhabdihi",
		"Memari",
		"Mongalkote",
		"Monteswar",
		"Nadanghat",
		"Purba Bardhaman Cyber Crime",
		"Purbasthali",
		"Raina",
		"Saktigarh",
	],
	"Purba Medinipore": [
		"Bhabanipur",
		"Bhagwanpur",
		"Bhupatinagar",
		"Chandipur",
		"Contai",
		"Contai Women",
		"Digha",
		"Digha Mohana Coastal",
		"Durgachak",
		"Egra",
		"Haldia",
		"Haldia Women",
		"Junput Coastal",
		"Khejuri",
		"Kolaghat",
		"Mahisadal",
		"Mandarmoni Coastal",
		"Marisda",
		"Moyna",
		"Nandakumar",
		"Nandigram",
		"Nayachar Coastal",
		"NDK",
		"Panskura",
		"Patashpur",
		"Purba Medinipore Cyber Crime",
		"Ramnagar",
		"Sutahata",
		"Talpatighat Coastal",
		"Tamluk",
	],
	Purulia: [
		"Adra",
		"Arsha",
		"Bagmundi",
		"Balarampur",
		"Bandwan",
		"Barabazar",
		"Bora",
		"Hura",
		"Jhalda",
		"Joypur",
		"Kashipur",
		"Kenda",
		"Kotshila",
		"Manbazar",
		"Neturia",
		"Para",
		"Puncha",
		"Purulia Cyber Crime",
		"Purulia Mufassil",
		"Purulia Town",
		"Purulia Women",
		"Raghunathpur",
		"Raghunathpur Women",
		"Santaldih",
		"Santuri",
		"Tamna",
	],
	"Raiganj Police District": [
		"Hemtabad",
		"Itahar",
		"Kaliaganj",
		"Karandighi",
		"Raiganj Women",
		"Raiganj",
		"Uttar Dinajpur  Cyber Crime",
	],
	"Ranaghat Police District": [
		"Chakdah",
		"Dhantala",
		"Gangnapur",
		"Hanskhali",
		"Haringhata",
		"Kalyani",
		"Mohanpur",
		"Ranaghat",
		"Ranaghat Women",
		"Santipur",
		"Taharpur",
	],
	"Sealdah G.R.P.": [
		"Ballygung G.R.P.S.",
		"Barasat G.R.P.S.",
		"Baruipur G.R.P.S.",
		"Berhampore G.R.P.S.",
		"Bongaon G.R.P.S.",
		"Chitpur G.R.P.S.",
		"Diamond Harbour G.R.P.S.",
		"Dumdum G.R.P.S.",
		"Jadavpur G.R.P.S.",
		"Krishnanagar G.R.P.S.",
		"Naihati G.R.P.S.",
		"Ranaghat G.R.P.S.",
		"Sealdah G.R.P.S.",
		"Sonarpur G.R.P.S.",
	],
	"Siliguri G.R.P.": [
		"Alipurduar G.R.P.S.",
		"Dalkhola G.R.P.S.",
		"Mal G.R.P.S.",
		"Malda G.R.P.S.",
		"Moynaguri G.R.P.S.",
		"New Coochbehar G.R.P.S.",
		"New Jalpaiguri G.R.P.S.",
		"Siliguri G.R.P.S.",
	],
	"Siliguri Police Commissionerate": [
		"Bagdogra",
		"Bhaktinagar",
		"Matigara",
		"New Jalpaiguri",
		"Pradhannagar",
		"Siliguri",
		"Siliguri Cyber Crime",
		"Siliguri Women",
	],
	"Sundarban Police District": [
		"Dholahat",
		"Frazerganj Coastal",
		"Gangasagar Coastal",
		"Gobardhanpur Coastal",
		"Harwood Point Coastal",
		"Kakdwip",
		"Kulpi",
		"Mandirbazar",
		"Mathurapur",
		"Namkhana",
		"Pathar Pratima",
		"Raidighi",
		"Sagar",
		"Sundarban PD Cyber Crime",
	],
	Kolkata: [
		"Alipore",
		"Amherst Street",
		"Amherst Street Women",
		"Anandapur",
		"Ballygunge",
		"Bansdroni",
		"Behala",
		"Behala Women",
		"Beliaghata",
		"Beniapukur",
		"Bhowanipur",
		"Bowbazar",
		"Burrabazar",
		"Burtolla",
		"Charu Market",
		"Chetla",
		"Chitpur",
		"Cossipore",
		"Ekbalpur",
		"Entally",
		"Garden Reach",
		"Garfa",
		"Gariahat",
		"Girish Park",
		"Hare Street",
		"Haridevpur",
		"Hastings",
		"Jadavpur",
		"Jorabagan",
		"Jorasanko",
		"Kalighat",
		"Karaya",
		"Karaya Women",
		"Kasba",
		"Kolkata Leather Complex",
		"Lake",
		"Maidan",
		"Manicktala",
		"Metiabruz",
		"Muchipara",
		"Nadial",
		"Narkeldanga",
		"Netaji Nagar",
		"New Alipore",
		"New Market",
		"North Port",
		"Panchasayar",
		"Park Street",
		"Parnashree",
		"Patuli",
		"Patuli Women",
		"Phoolbagan",
		"Posta",
		"Pragati Maidan",
		"Purba Jadavpur",
		"Rabindra Sarobar",
		"Rajabagan",
		"Regent Park",
		"Sarsuna",
		"Shakespeare Sarani",
		"Shyampukur",
		"Sinthee",
		"South Port",
		"Survey Park",
		"Tala",
		"Taltala",
		"Taltala Women",
		"Tangra",
		"Taratala",
		"Thakurpukur",
		"Tiljala",
		"Tollygunge",
		"Tollygunge Women",
		"Topsia",
		"Ultadanga",
		"Ultadanga Women",
		"Watgunge",
		"Watgunge Women",
		"West Port",
	],
};
