import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import axios from "axios";
import Main from "./pages/main";
import { WarningOutlined } from "@ant-design/icons";

// axios.defaults.baseURL = "https://greenpuja.wbpcb.gov.in/api/";
// axios.defaults.baseURL = "http://monster2.distronix.in:7100/api/";
axios.defaults.baseURL = "https://greenpuja.wbpcb.gov.in:7443/api/";
function App() {
	const [mode, setMode] = React.useState(false);
	React.useEffect(() => {
		axios
			.post("/get_maintenance_mode")
			.then(res => {
				console.log(res.data.data.value);
				if (res.data.data.value === "on") {
					setMode(true);
				} else {
					setMode(false);
				}
			})
			.catch(err => console.log(err));
	}, []);
	return (
		<div className="App">
			{mode ? (
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						background: "#f7f7f7",

						flexDirection: "column",
					}}
				>
					<WarningOutlined style={{ color: "#ff9800", fontSize: 40 }} />
					<p style={{ fontSize: 22 }}>website is under maintenance mode</p>
				</div>
			) : (
				<Main />
			)}
		</div>
	);
}

export default App;
